import moment from 'moment'
import React, { memo, useCallback, useState } from 'react'
import InformationChip from '../Components/InformationChip'
import Accordion from 'react-bootstrap/Accordion';
import copy from "copy-to-clipboard";
import './custom.css'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { ChatHistoryBodyNew } from './ChatHistoryBodyNew'
import "react-tooltip/dist/react-tooltip.css";
import clsx from 'clsx';
import ChatOpenExternalResource from '../Components/ChatOpenExternalResource';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import TagsShow from '../../TagsShow/TagsShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopesBulk, faEnvelopeSquare, faReply } from '@fortawesome/free-solid-svg-icons';
import ReviewDynamicFormModal from '../../reviewModule/ReviewDynamicFormModal';
const ChatHistoryList = ({ TicketListDatas, customerDetailData, ticketDataRedux, currenOpenedTicketData }: any) => {
    const [bodyData, setBodyData] = useState<any>({});
    const [selectedKey, setSelectedKey] = useState<any>([]);


    const updatedChatHistory: any = TicketListDatas?.sort((a: any, b: any) => {
        let a1: any = new Date(b.ticketdate)
        let a2: any = new Date(a.ticketdate)
        return a1 - a2
    }).map((ticket: any) => {
        if (ticket?.id == currenOpenedTicketData?.id) {
            ticket.isOpenedTicket = true
        }
        else {
            ticket.isOpenedTicket = false
        }
        return ticket
    })


    const handleSelect = async (eventKey: any) => {
        if (eventKey.length > 0 && !selectedKey.includes(+(eventKey[eventKey.length - 1]))) {
            setSelectedKey((prevSetSelectedKey: any) => [...prevSetSelectedKey, +eventKey[eventKey.length - 1]]);
            const response = await postRequest(URL_EndPoints()?.getticketreplies, { "ticketid": +(eventKey[eventKey.length - 1]) }, null)
            if (response.status == 200) {
                const data: any = response?.data?.data
                setBodyData((prevState: any) => ({ ...prevState, [+(eventKey[eventKey.length - 1])]: data }));
            }
        } else {
        }
    };


    function callback(value: any) {
        let val = ""
        for (let i = 0; i < value.length; i++) {
            if (value[i] == 0 || value[i] == 1 || value[i] == 2 || value[i] == 3 || value[i] == 4 || value[i] == 5 || value[i] == 6 || value[i] == 7 || value[i] == 8 || value[i] == 9) {
                val = val + value[i];
            }
        }
        copy(val)
        toast.success(`Copied to clipboard (${value} ) - `, { position: "top-center" })
    }

    const copyToClipboards = React.useCallback(callback, [callback])
    const [show, setShow] = useState(false)
    const [reference_id, setReference_id] = useState<number>(0)
    const reviewHandler = (items: any) => {
        setShow(true)
        setReference_id(items?.id)
    }

    return (
        <>
            {
                show ? <ReviewDynamicFormModal
                    show={show}
                    onHide={() => setShow(false)}
                    initialValue={{
                        form_id: 2,
                        reference_id: reference_id,
                    }}
                /> : <></>
            }

            {
                <div className='customer-detail-bodys' id={'kt_chat_messenger_body'} >
                    <div
                        className={clsx('scroll-y me-n5 pe-5  max')}
                        data-kt-element='messages'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                        data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                    >
                        <Accordion defaultActiveKey="" onSelect={handleSelect} alwaysOpen>
                            {
                                updatedChatHistory?.length > 0 ? updatedChatHistory?.map((items: any, index: number) => {

                                    let bgColor = items?.isOpenedTicket ? "chatHistryBgColor" : items?.ticketstatus?.id == 4 ? "bg-white rounded" : "bg-light-secondary rounded"

                                    let chatWidth = items?.isOpenedTicket ? "100%" : items?.ticketstatus?.id == 4 ? "100%" : "100%"

                                    let isClosedTicket = items?.ticketstatus?.id == 4 ? "muted" : ""

                                    let ticketClassCombination = items?.ticketstatus?.id == 0 ? "warning" : items?.ticketstatus?.id == 1 ? "danger" : items?.ticketstatus?.id == 2 ? "warning" : items?.ticketstatus?.id == 3 ? "success" : items?.ticketstatus?.id == 4 ? "dark" : items?.ticketstatus?.id == 5 ? "primary" : items?.ticketstatus?.id == 6 ? "primary" : "primary"



                                    return (


                                        <Accordion.Item eventKey={'' + items.id} className="margins" >
                                            <Accordion.Header>


                                                <div className={`col-md-11 d-flex justify-content-between align-items-center ${bgColor}  p-2 py-0 `} style={{ width: chatWidth, color: items?.isOpenedTicket ? "black" : "" }}>



                                                    {/* <div className={`col-md-11 d-flex align-items-center bg-${items?.ticketstatus?.id == 3  || items?.ticketstatus?.id == 4  ? "white " : "light-secondary rounded"} p-2 py-0`} style={{width:items?.ticketstatus?.id == 3  || items?.ticketstatus?.id == 4 ? "98%" : ""}}> */}


                                                    <div className='flex-grow-1 me-2'>


                                                        <span className={`svg-icon svg-icon-primary me-2  `}>
                                                            <i className={`bi bi-ticket-detailed fs-4 text-${isClosedTicket ? "danger fw-bold " : "dark"}`}></i>
                                                        </span>

                                                        <span className={`fw-bold text-${isClosedTicket && !items?.isOpenedTicket ? "muted" : "dark"} fs-7 `} onClick={() => copyToClipboards(items?.ticketno)} >
                                                            #{items?.ticketno}  {items?.title}
                                                        </span>

                                                        <span className={`fw-bold cursor-pointer ms-5 me-2`} onClick={() => reviewHandler(items)} >
                                                            <FontAwesomeIcon icon={faEnvelopesBulk} className='fs-2 text-muted cursor-pointer' />
                                                        </span>


                                                        <span className='text-muted fw-semibold d-block mt-2'>



                                                            <InformationChip

                                                                SelectedString={items?.ticketstatus?.name ? items?.ticketstatus?.name : "No Ticket Status"}

                                                                classes={ticketClassCombination}
                                                                isClosedCss={isClosedTicket}


                                                            ></InformationChip>


                                                            <InformationChip
                                                                SelectedString={items?.ticket_assigns?.length > 0 ? items?.ticket_assigns[0]?.name : "Not Assigned"}

                                                                classes={items?.ticket_assigns.length > 0 ? "dark" : "warning"}

                                                                Id={""}

                                                                toolTipName="Ticket Assignees"
                                                                isClosedCss={isClosedTicket}


                                                            ></InformationChip>


                                                            <InformationChip
                                                                SelectedString={items?.source ? items?.source : "No Source"} classes={"dark"}
                                                                isClosedCss={isClosedTicket}

                                                            ></InformationChip>

                                                            <InformationChip
                                                                SelectedString={items?.tags ? <TagsShow Tag={items?.tags}></TagsShow> : "No Tag"} classes={"primary"}
                                                                isClosedCss={isClosedTicket}
                                                            ></InformationChip>


                                                        </span>
                                                    </div>


                                                    <span className='fw-bold fs-9 text-dark py-1 text-end mb-1'>
                                                        <span className='text-danger'>
                                                            <ChatOpenExternalResource ticketDataRedux={ticketDataRedux} SelectedString={"External"} classes={""} items={items} classname={""} Toggleid={""} externalCss={"reply"} ExternalTitle={<i className="fs-5 text-primary fa-solid fa-share">  </i>}></ChatOpenExternalResource>
                                                        </span>
                                                        <br />


                                                        <span className={`text-${isClosedTicket && !items?.isOpenedTicket ? "muted" : ""}`}>  {items?.updatedAt.toUpperCase()}</span>
                                                        <br />


                                                        <span className={`text-${bgColor == "chatHistryBgColor" ? "light-white" : "muted"} mb-2`}>( {moment(items?.ticketdate).format("YYYY-MM-DD")} )</span>


                                                    </span>


                                                </div>


                                            </Accordion.Header>
                                            <Accordion.Body>


                                                {bodyData['' + items.id] ? (
                                                    <div><ChatHistoryBodyNew ticketreplies={bodyData['' + items.id]} customerDetailData={customerDetailData}></ChatHistoryBodyNew></div>
                                                ) : (
                                                    <div>Loading...</div>
                                                )}


                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }) : ''
                            }
                        </Accordion>
                    </div>
                </div>
            }
        </>
    )
}
export default memo(ChatHistoryList)