export const AuditFormListColumns = () => [
    {
        id: 2,
        reorder: true,
        name: "Form Name",
        selector: (row: any) => row?.form_name ? row?.form_name : "NA",
        sortable: true,
        width: "150px"
    },
    {
        id: 3,
        reorder: true,
        name: "Has Multiple Executive",
        selector: (row: any) => row?.has_multiple_executive == 1 ? "Yes" : "No",
        sortable: true,
        width: "100px"
    },


]

