import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import AutoCompleteAddressGoogle from './AutoCompleteAddressGoogle/AutoCompleteAddressGoogle'
import GoogleMaps from './AutoCompleteAddressGoogle/GoogleMaps'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { CheckboxModal, ColModal, GroupModal, LabelModal, RowModal, SelectModal } from '../../widgets/forms/FormsModals'
import { toast } from 'react-toastify'
import Not_Found from '../../Not_Found'
import { FetchAddressPincodeByLatLngUtils } from '../../googleMapUtility/FetchAddressPincodeByLatLngUtils'
import AutoCompleteAddressGoogleUtils from '../../googleMapUtility/AutoCompleteAddressGoogleUtils'
let addresstype = [{ name: "Home", id: "Home" }, { name: "Office", id: "Office" }, { name: "Other", id: "Other" },]
const UpdateAddresses = ({ showModal, handleClose, addressData }: any) => {

    const [loader, setloader] = useState<any>(false)
    const [markerPositionLatLng, setMarkerPositionLatLng] = useState<any>({ lat: +addressData?.latitude ? +addressData?.latitude : null, lng: +addressData?.longitude ? +addressData?.longitude : null })
    const [address, setAddress] = useState<any>("")
    const [pincode, setPincode] = useState<any>("")

    useEffect(() => {
        addressData?.latitude && setMarkerPositionLatLng({ lat: +addressData?.latitude, lng: +addressData?.longitude })
    }, [addressData?.latitude])

    useEffect(() => {
        markerPositionLatLng?.lat && FetchAddressPincodeByLatLngUtils(markerPositionLatLng?.lat, markerPositionLatLng?.lng, setAddress, setPincode, payload, setPayload);
    }, [markerPositionLatLng?.lat])

    const [payload, setPayload] = useState<any>({
        customerid: addressData?.customerid,
        addressid: addressData?.id,
        address: addressData?.address,
        flatno: addressData?.flatno,
        latitude: addressData?.latitude,
        longitude: addressData?.longitude,
        pincode: addressData?.pincode,
        addresstype: addressData?.addresstype,
        default: addressData?.default,
        parking_no: addressData?.parking_no,
    })

    const handleMarkerDragEnd = (event: any) => {
        const { latLng } = event;
        const newLat = latLng.lat();
        const newLng = latLng.lng();
        setMarkerPositionLatLng({ lat: newLat, lng: newLng });
        FetchAddressPincodeByLatLngUtils(newLat, newLng, setAddress, setPincode, payload, setPayload);
    };

    const updateAddressHandler = async () => {
        setloader(true)
        
        if (markerPositionLatLng?.lat) {
            payload.latitude = markerPositionLatLng?.lat
            payload.longitude = markerPositionLatLng?.lng
        }

        const response = await postRequest(URL_EndPoints(null)?.updateCustomerAddressXX, payload, setloader)
        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            handleClose()
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            handleClose()
        }
    }



    return (
        <>
            {/* fullscreen */}
            <Modal size='lg' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <RowModal classes="d-flex flex-wrap">

                            <ColModal classes="mb-4 col-12 ">
                                <GroupModal classes="" >
                                    <LabelModal show={false} classes="" title="Address" />
                                    <AutoCompleteAddressGoogleUtils address={address} setMarkerPositionLatLng={setMarkerPositionLatLng} />
                                </GroupModal>
                            </ColModal>


                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-6">
                                <GroupModal classes="" >
                                    <LabelModal show={false} classes="" title="Pincode" />
                                    <input
                                        placeholder='pincode no. '
                                        name='pincode'
                                        type='text'
                                        value={payload.pincode}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>


                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-6">
                                <GroupModal classes="" >
                                    <LabelModal show={false} classes="" title="Pincode" />
                                    <input
                                        placeholder='flat no. '
                                        name='flatno'
                                        type='text'
                                        value={payload.flatno}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>


                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-6">
                                <GroupModal classes="" >
                                    <LabelModal show={false} classes="" title="parking_no" />
                                    <input
                                        placeholder='Parking no. '
                                        name='parking_no'
                                        type='text'
                                        value={payload.parking_no}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-6">
                                <GroupModal classes="" controlId="" >
                                    <LabelModal show={false} classes="" title="addresstype" />
                                    <SelectModal
                                        classes="mb-2"
                                        title="Address Type"
                                        name="addresstype"
                                        keyName="name"
                                        id="id"
                                        isData={addresstype}
                                        payload={payload}
                                        setPayload={setPayload}
                                        defValue={addressData?.addresstype}
                                    />
                                </GroupModal>
                            </ColModal>

                        </RowModal>
                        <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-4">
                            <GroupModal classes="" >

                                <CheckboxModal
                                    classes=""
                                    name="default"
                                    payload={payload}
                                    setPayload={setPayload}
                                    defValue={addressData?.default}
                                />

                            </GroupModal>
                        </ColModal>

                        {markerPositionLatLng?.lat && markerPositionLatLng?.lng ? <GoogleMaps markerPositionLatLng={markerPositionLatLng} handleMarkerDragEnd={handleMarkerDragEnd} /> : <Not_Found headerTitle={!markerPositionLatLng?.lat ? 'Search address ( No Address found )' : ""}></Not_Found>}

                        <div className=' col-12 w-100 d-flex justify-content-end mt-8 me-'>
                            <Button variant="primary btn-sm text-end " disabled={loader} onClick={updateAddressHandler}>
                                {
                                    loader ?
                                        <div className='d-flex'>
                                            <div className='spinner-border mr-15' role='status'></div>
                                            <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating....</h4>
                                        </div>
                                        : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Update </h4>
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default UpdateAddresses
