
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getRequest, postRequest } from '../Server/Requests';
import { URL_EndPoints } from '../Server/URL_EndPoints';
import { adminQueryGenerator, adminToastRunner } from '../../modules/admins/adminUtils';
import { MultipleCheckbox, MultipleRadio, SingleSelectBox, SingleTextArea, SingleTextBox } from './ReviewComponents';
import SkeletonLoader from '../IsLoading/SkeletonLoader';
import AdminSingleSelectFilter from '../../modules/admins/adminForms/adminFormComponents/AdminSingleSelectFilter';
interface ReviewDynamicFormModalProps {
  show: boolean;
  onHide: () => void;
  initialValue: {
    form_id: number;
    reference_id: number;
  };
}

const ReviewDynamicFormModal: React.FC<ReviewDynamicFormModalProps> = ({ show, onHide, initialValue }) => {
  const removeAnwerText = ["select", "radio", "checkbox"]
  const [fields, setFields] = useState<any>([]);
  const [adminList, setAdminList] = useState<any>([]);
  const [pending, setPending] = useState<any>(false);
  const [formLoader, setFormLoader] = useState<any>(false);
  const [adminId, setAdminId] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    answers: fields?.questions?.map((ele: any) => (removeAnwerText?.includes(ele?.answer_type) ? {
      question_id: ele?.id,
      selected_option_ids: [],
    } : {
      question_id: ele?.id,
      answer_text: "",
    }))
  });

  useEffect(() => {
    if (show) {
      fetchFormFields();
    }
  }, [show]);

  const fetchFormFields = async () => {
    try {
      setPending(true)
      const response = await getRequest(
        URL_EndPoints(adminQueryGenerator("getAuditForm", { form_type: initialValue?.form_id }))?.getAuditForm,
        setPending
      );
      setFormData({
        answers: response?.data?.data?.formData?.questions?.map((ele: any) => (removeAnwerText?.includes(ele?.answer_type) ? {
          question_id: ele?.id,
          selected_option_ids: [],
        } : {
          question_id: ele?.id,
          answer_text: "",
        }))
      })

      if (response?.data?.data?.formData?.form?.has_multiple_executive == 1) {
        const adminData = await getRequest(
          URL_EndPoints()?.getAdminList,
          null
        );
        setAdminList(adminData?.data?.data)
      }

      setFields(response?.data?.data?.formData || []);
    } catch (error) {
      console.error('Error fetching form fields:', error);
    }
  };

  const handleChange = (question_id: number, name: string, value: any) => {
    setFormData((prevData: any) => {
      const updatedAnswers = prevData.answers.map((answer: any) =>
        answer.question_id === question_id ? { ...answer, [name]: value } : answer
      );

      const existingQuestionIndex = updatedAnswers.findIndex((ans: any) => ans.question_id === question_id);
      if (existingQuestionIndex === -1) {
        updatedAnswers.push({
          question_id: question_id,
          [name]: value,
        });
      }

      return { ...prevData, answers: updatedAnswers };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setFormLoader(true)
    try {
      const filteredData = formData?.answers?.filter((item: any) => {
        return (item.answer_text && item.answer_text.trim() !== '') || (item.selected_option_ids && item.selected_option_ids.length > 0);
      });
      const newPayload: any = {
        form_id: fields?.form?.id,
        reference_id: initialValue?.reference_id,
        answers: filteredData
      }

      if (fields?.form?.has_multiple_executive == 1) {
        newPayload.submitted_for_user_id = adminId
      }

      const response = await postRequest(URL_EndPoints()?.submitAuditForm, newPayload, setFormLoader);
      adminToastRunner(response)
      onHide();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const FieldComponents: any = {
    text: SingleTextBox,
    textarea: SingleTextArea,
    checkbox: MultipleCheckbox,
    radio: MultipleRadio,
    select: SingleSelectBox
  };

  const renderField = (field: any) => {
    const Component = FieldComponents[field.answer_type];
    if (!Component) return <></>;
    return (
      <Component
        key={field.id}
        questionId={field.id}
        questionText={field.question_text}
        options={field.options}
        isRequired={field.is_required}
        onChangeHandler={handleChange}
        formData={formData}
      />
    );
  };

  const onChangeHandler = (event: any, name: any) => {
    setAdminId(event ? event?.value : event)
  }

  return (
    <>
      {
        <Modal show={show} onHide={onHide} size="lg">
          <Modal.Header closeButton>
            {
              pending ? <SkeletonLoader rowCount={2} title="" /> : <Modal.Title>{fields?.form?.form_name || "Review Form"}</Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              {
                fields?.form?.has_multiple_executive == 1 ? <div className='mb-4'>
                  <label htmlFor="Select Admin"> Select Admin</label>
                  <AdminSingleSelectFilter
                    onChangeHandler={onChangeHandler}
                    HeaderTitle="Select Admin"
                    SelectData={adminList}
                    displayFields={["first_name", "last_name", "phone"]}
                    id="id"
                    name="submitted_for_user_id"
                    defaultData={null}
                    searchable={false}
                  />
                </div> : <></>
              }

              {
                pending ? <SkeletonLoader rowCount={10} title="Review Form Getting Loaded" /> : <>

                  {fields?.questions?.map((field: any) => (
                    <Form.Group key={field.id} className="mb-4">
                      {renderField(field)}
                    </Form.Group>
                  ))}

                  <div className='text-end mt-4'>
                    <Button type="submit" variant={formLoader ? "success" : "primary"} disabled={formLoader}>
                      {
                        formLoader ? "Submiting..." : "Submit"
                      }
                    </Button>
                  </div>
                </>
              }
            </form>

          </Modal.Body>
        </Modal>
      }


    </>
  );
};

export default ReviewDynamicFormModal;



{/* <ReviewDynamicFormModal
show={showForm}
onHide={() => setShowForm(false)}
initialValue={{
  form_id: 1,
  reference_id: 1,
}}
/> */}