import React, { useEffect, useState } from 'react'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { getRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton'
import { AuditFormListColumns } from './AuditFormListColumns'
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import CreateAuditForm from '../../consts/reviewModule/CreateAuditForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons';

const AuditFormList = () => {
    const [sortedColumns, setSortedColumns] = useState<any>(AuditFormListColumns())
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getAuditFormList = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints()?.getForms, setPending)
        setFilterData(response?.data?.data?.forms)
        setData(response?.data?.data)
    }

    useEffect(() => {
        getAuditFormList()
    }, [])

    const [showAuditForm, setShowAuditForm] = useState(false)

    return (
        <>
            <AdminClientSidePaginationLists
                title="Audit Form List"
                reorderColumnTitle="auditFormList"
                sortedColumns={[
                    {
                        name: 'Show Audit',
                        cell: (row: any) => <ReadOnly row={row} />,
                        reorder: true,
                        id: 1,
                        width: "100px"
                    }, ...sortedColumns]}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={() => <></>}
                // clientSideFilterComponent={AllotmentFilter}
                keyName={"auditFormList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                    <Refresh refreshNow={getAuditFormList} />
                    <BulkUtilButton title="Create Audit Form" filteredSelectedRows="static" setShow={setShowAuditForm} />
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)} clientSideFilterComponent={undefined} />

            {
                showAuditForm ? <CreateAuditForm
                    show={showAuditForm}
                    onHide={() => setShowAuditForm(false)}
                    readOnlyForm={false}
                /> : <></>
            }
        </>
    )
}

export default AuditFormList



const ReadOnly = ({ row }: any) => {
    const [show, setShow] = useState(false)
    return (
        <>
            < FontAwesomeIcon icon={faEye} className='fs-4 me-3 text-primary' onClick={() => setShow(true)} />
            {
                show ? <CreateAuditForm
                    show={show}
                    onHide={() => setShow(false)}
                    readOnlyForm={true}
                    row={row}
                /> : <></>
            }

        </>
    )
}