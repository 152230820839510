import React from 'react'
import { Form } from 'react-bootstrap'
const AdminSelectCheckbox = ({ onChangeHandler, HeaderTitle = "", payload = {}, name = "", inputType = "checkbox", label = "" }: any) => {
  return (
    <Form.Check
      type="checkbox"
      label={HeaderTitle}
      name={name}
      id={`checkbox-${name}`}
      checked={payload[name]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        onChangeHandler({ value: newValue }, name, false)
      }}
      className='border-white'
    />
  )
}
export default AdminSelectCheckbox

