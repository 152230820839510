// SingleTextBox.tsx
import React from 'react';
import { Form } from 'react-bootstrap';

interface SingleTextBoxProps {
    questionId: number;
    questionText: string;
    isRequired: boolean;
    onChangeHandler: (questionId: number, name: string, value: any) => void;
    formData: any;
}


export interface Options {
    id: number;
    option_text: string;
    is_active: number;
    createdAt: number;
    updatedAt: number;
}


export const SingleTextBox: React.FC<SingleTextBoxProps> = ({ questionId, questionText, isRequired, onChangeHandler, formData }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(questionId, 'answer_text', event.target.value);
    };

    return (
        <Form.Group>
            <Form.Label>{questionText} <strong className='text-danger ms-2'>{isRequired && ' Required'}</strong></Form.Label>
            <Form.Control
                type="text"
                placeholder={questionText}
                value={formData?.answers?.find((ans: any) => ans?.question_id === questionId)?.answer_text || ''}
                onChange={handleChange}
                required={isRequired}
            />
        </Form.Group>
    );
};



interface SingleTextAreaProps {
    questionId: number;
    questionText: string;
    isRequired: boolean;
    onChangeHandler: (questionId: number, name: string, value: any) => void;
    formData: any;
}

export const SingleTextArea: React.FC<SingleTextAreaProps> = ({ questionId, questionText, isRequired, onChangeHandler, formData }) => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChangeHandler(questionId, 'answer_text', event.target.value);
    };

    return (
        <Form.Group>
            <Form.Label>{questionText} <strong className='text-danger ms-2'>{isRequired && ' Required'}</strong></Form.Label>
            <Form.Control
                as="textarea"
                rows={3}
                placeholder={questionText}
                value={formData?.answers?.find((ans: any) => ans.question_id === questionId)?.answer_text || ''}
                onChange={handleChange}
                required={isRequired}
            />
        </Form.Group>
    );
};


interface MultipleRadioProps {
    questionId: number;
    questionText: string;
    options: Options[];
    isRequired: boolean;
    onChangeHandler: (questionId: number, name: string, value: any) => void;
    formData: any;
}

export const MultipleRadio: React.FC<MultipleRadioProps> = ({ questionId, questionText, options, isRequired, onChangeHandler, formData }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(questionId, 'selected_option_ids', [+event.target.value]);
    };

    return (
        <Form.Group>
            <Form.Label>{questionText} <strong className='text-danger ms-2'>{isRequired && ' Required'}</strong></Form.Label>
            {options.map((option, index) => (
                <Form.Check
                    key={index}
                    type="radio"
                    label={option?.option_text}
                    name={`radio-${questionId}`}
                    value={option?.id}
                    // checked={formData?.answers?.find((ans: any) => ans.question_id === questionId)?.selected_option_ids[0]?.includes(option?.id) || false}
                    onChange={handleChange}
                    required={isRequired}
                    className='mb-3'
                />
            ))}
        </Form.Group>
    );
};


interface MultipleCheckboxProps {
    questionId: number;
    questionText: string;
    options: Options[];
    isRequired: any;
    onChangeHandler: (questionId: number, name: string, value: any) => void;
    formData: any;
}

export const MultipleCheckbox: React.FC<MultipleCheckboxProps> = ({ questionId, questionText, options, isRequired, onChangeHandler, formData }) => {
    const existingAnswer = formData?.answers?.find((ans: any) => ans.question_id === questionId)?.selected_option_ids || [];
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const addedOrRemove = existingAnswer?.find((ele: any) => ele == value)
        onChangeHandler(questionId, 'selected_option_ids', addedOrRemove ? existingAnswer?.filter((ele: any) => ele != value) : [...existingAnswer, +value]);
    };

    return (
        <Form.Group>
            <Form.Label>{questionText} <strong className='text-danger ms-2'>{isRequired && ' Required'}</strong></Form.Label>
            {options.map((option, index) => (
                <Form.Check
                    key={index}
                    type="checkbox"
                    label={option?.option_text}
                    value={option?.id}
                    checked={existingAnswer?.includes(option?.id) || false}
                    onChange={(event) => handleChange(event)}
                    className='mb-3'
                    required={isRequired}
                />
            ))}
        </Form.Group>
    );
};


interface SingleSelectBoxProps {
    questionId: number;
    questionText: string;
    options: Options[];
    isRequired: boolean;
    onChangeHandler: (questionId: number, name: string, value: any) => void;
    formData: any;
}

export const SingleSelectBox: React.FC<SingleSelectBoxProps> = ({ questionId, questionText, options, isRequired, onChangeHandler, formData }) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChangeHandler(questionId, 'selected_option_ids', [+event.target.value]);
    };

    return (
        <Form.Group>
           <Form.Label>{questionText} <strong className='text-danger ms-2'>{isRequired && ' Required'}</strong></Form.Label>
            <Form.Select
                value={formData?.answers?.find((ans: any) => ans.question_id === questionId)?.selected_option_ids[0] || ''}
                onChange={handleChange}
                required={isRequired}
            >
                <option value="">Select an option</option>
                {options.map((option, index) => (
                    <option key={index} value={option?.id}>{option?.option_text}</option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

