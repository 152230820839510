
import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

const AdminCreatebleDropdown = ({ handleInputTextValue, apiUrl, initialPayload, keyName, getApiData, Component, props, placeHolder }: any) => {
    const [items, setItems] = useState(['jack', 'lucy']);
    const [name, setName] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const addItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        initialPayload[keyName] = name
        handleInputTextValue && handleInputTextValue(apiUrl, initialPayload, getApiData)
        if (name && !items.includes(name)) {
            setItems([...items, name]);
            setName('');
            setIsFocused(false);
        }
    };

    const toggleSelectBox = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className="d-flex align-items-center position-relative">
            {isExpanded && (
                <div
                    style={{
                        width: isFocused ? "90%" : "90%",
                        transition: "width 0.3s ease",
                    }}
                >
                    {Component && <Component {...props} />}
                </div>
            )}

            {!isExpanded && (
                <div
                    style={{
                        height: "38px",
                        display: "flex",
                        alignItems: "center",
                        width: isFocused ? "100%" : "100%",
                        transition: "width 0.3s ease",
                    }}
                    className="me-2"
                >
                    <input
                        type="text"
                        className="form-control rounded-0 border border-secondary"
                        placeholder={placeHolder}
                        ref={inputRef}
                        value={name}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => !name && setIsFocused(false)}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                            flex: 1,
                            padding: "8px",
                            borderRadius: "4px",
                            transition: "flex 0.3s ease",
                            height: "100%",
                            borderRight: "none",
                            width: "99%",
                        }}
                    />
                    {isFocused && (
                        <button
                            className="btn btn-sm btn-success border border-success text-white rounded-0"
                            style={{
                                height: "100%",
                                width: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                            onClick={addItem}
                            disabled={!name}
                        >
                            Add
                        </button>
                    )}
                </div>
            )}

            {!isFocused && (
                <div
                    className="btn btn-sm btn-primary border border-primary text-white rounded-0 position-absolute"
                    style={{
                        top: "0",
                        right: "0",
                        height: "38px",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                    onClick={toggleSelectBox}
                >
                    <FontAwesomeIcon
                        icon={isExpanded ? faPlusSquare : faMinusSquare}
                        className="fs-2 cursor-pointer"
                    />
                </div>
            )}
        </div>





    );
};

export default AdminCreatebleDropdown;






//   const getFormType = async () => {
//         const response = await getRequest(URL_EndPoints()?.["getContentApi"], null)
//     }

//     const addContentToDropdown = async (value: any) => {
//         const isConfirmed = window.confirm("Are you sure you want to add this content ?");
//         if (!isConfirmed) {
//             return;
//         }
//         const response = await postRequest(URL_EndPoints()?.["addContentApiHere"], { name: value }, addContentLoader)
//         adminToastRunner(response)
//         if (response?.data?.status == 200) {
//             getFormType()
//         }

//     }




// {
//     <div className='mb-4'>
//         <label htmlFor="Select Admin"> Select Admin</label>
//         <AdminSingleSelectFilter

//         />


//         <AdminCreatebleDropdown
//             handleInputTextValue={addContentToDropdown}
//             placeHolder="Add New Content"
//             Component={AdminSelectSearchable}
//             props={{
//                 HeaderTitle: 'Select Status Group',
//                 onChangeHandler: onChangeHandler,
//                 selectData: adminList,
//                 displayFields: ["name"],
//                 id: "id",
//                 name: "status_group_id",
//                 searchable: false,
//                 setSeacrch: null,
//                 isLoading: null,
//                 filters: null,
//                 isMulti: false,
//                 defaultData: null
//             }}
//             default
//         />
//     </div>
// }