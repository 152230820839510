import React from 'react';
import Select from 'react-select';

interface CreateTextProps {
    onChangeHandler?: (value: string | number, questionId: number, fieldName: string) => void;
    value: string;
    questionId: number;
    fieldName: string;
    placeholder?: string;
    label?: string;
}

export const CreateText: React.FC<CreateTextProps> = ({
    onChangeHandler = () => { },
    value,
    questionId,
    fieldName,
    placeholder = '',
    label = ''
}) => {
    const handleChange = (event: any) => {
        onChangeHandler(event.target, questionId, fieldName);
    };

    return (
        <div className="form-group mb-3">
            {label && <label className="form-label">{label}</label>}
            <input
                type="text"
                className='form-control'
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </div>
    );
};


interface CreateInputProps {
    onChangeHandler?: (value: any, questionId: number, fieldName: string) => void;
    value: string | boolean;
    questionId: number;
    fieldName: string;
    label?: string;
}

export const CreateInput: React.FC<CreateInputProps> = ({
    onChangeHandler = () => { },
    value,
    questionId,
    fieldName,
    label = '',
}) => {
    const handleChange = (event: any) => {
        const newValue = { value: event.target.checked ? 1 : 0 }
        onChangeHandler(newValue, questionId, fieldName);
    };

    return (
        <div className="form-group mb-3">
            {(
                <input
                    type="checkbox"
                    id={`checkbox-${fieldName}`}
                    className='form-check-input me-3'
                    checked={value as boolean}
                    onChange={handleChange}
                    style={{ borderColor: 'gray' }}
                />
            )}
            {label && <label className="form-label" htmlFor={`input-${fieldName}`}>{label}</label>}
        </div>
    );
};


interface CreateCreatableSelectSinglMultioProps {
    headerTitle: string;
    onChangeHandler?: (value: any, questionId: any, fieldName: any) => void;
    selectData: any[];
    displayFields: string[];
    id: string;
    name: any;
    defaultData?: any;
    searchable?: boolean;
    setSearch?: any;
    isLoading?: boolean;
    filters?: any;
    isMulti?: boolean;
    questionId?: any;
    isDisabled?: boolean;
}

export const CreateCreatableSelectSinglMulti: React.FC<CreateCreatableSelectSinglMultioProps> = ({
    headerTitle,
    onChangeHandler = () => { },
    selectData = [],
    displayFields = [],
    id,
    name,
    defaultData,
    searchable = false,
    setSearch,
    isLoading = false,
    filters,
    isMulti = false,
    questionId = null,
    isDisabled = false
}) => {

    const [showData, setShowData] = React.useState<any[]>([]);

    React.useEffect(() => {
        const updatedData = selectData?.map((ele: any) => ({
            label: displayFields?.map((key: any) => ele[key] || "NA").join(" "),
            value: ele[id],
            item: ele
        }));
        setShowData(updatedData);
    }, [selectData, displayFields, id]);

    const debouncing = (event: any) => {
        if (setSearch) {
            setSearch(event, filters);
        }
    };

    return (
        <div className='mb-2'>
            <Select
                placeholder={headerTitle}
                isMulti={isMulti}
                isClearable
                isSearchable={searchable}
                onInputChange={searchable ? debouncing : undefined}
                options={showData}
                onChange={(event) => onChangeHandler && onChangeHandler(
                    { value: isMulti ? event?.map((ele: any) => ele?.value) : event?.value },
                    questionId,
                    name,
                )}
                defaultValue={defaultData?.item}
                // styles={{ control: (base) => ({ ...base, minHeight: '38px' }) }}
                isLoading={isLoading}
                isDisabled={isDisabled}
            />
        </div>
    );
};

